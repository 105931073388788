import React from 'react';
import './RSVPSection2.css';

const RSVPSection2 = () => {
    return (
        <div className="rsvp-container">
            <h1>Weining & Yuting</h1>
            <p>Mars 09, 2035, 2:00 PM – 11:00 PM</p>
            <p>The Westin Sanya Haitang Bay Resort</p>
        </div>
    );
};

export default RSVPSection2;